/**
 * @name: 视频上传组件入口
 * @author: itmobai
 * @date: 2023-03-17 17:25
 * @description：index
 * @update: 2023-03-17 17:25
 */
import VideoUpload from "./VideoUpload.vue"
import Vue from "vue";

// @ts-ignore
VideoUpload.install = (vue: Vue) => {
  Vue.component(VideoUpload.name, VideoUpload);
}

export default VideoUpload;
