import Vue from 'vue'
import Vuex from 'vuex'
import tagsView from './tabbar/index'
import useMenuStore from './menu/index'
import useUserStore from './user/index'
import { getToken } from "@/utils/token"

Vue.use(Vuex)

const store=new Vuex.Store({
  state: {
    routerFlag: false
  },
  getters: {
    // token
    token: (state: any) => (state.useUserStore.token || getToken()),
    // 用户信息
    userInfo: (state: any) => state.useUserStore.userInfo
  },
  mutations: {
    SET_ROUTEFLAG(state, status) {
      state.routerFlag = status;
    }
  },
  actions: {
  },
  modules: {
    tagsView,
    useMenuStore,
    useUserStore,
  }
})

export default store
