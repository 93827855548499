<script>
/**
 * @name: 地图选择组件
 * @author: itmobai
 * @date: 2023-04-21 13:39
 * @description：地图选择组件
 * @update: 2023-04-21 13:39
 */
import {mapPlaceGeocoderLocationApi, mapPlaceSuggestionApi} from "@/apis/party"

export default {
  props: {
    value: {
      type: Object,
      required: true
    },
    mapKey: {
      type: String,
      default: ""
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      dialogVisible: false,
      results: [],
      select: "",
      loading: "",
      scriptDom: null,
      map: null,
      markerLayer: null
    }
  },
  methods: {
    /**
     * 加载腾讯地图资源
     */
    loadTencentMap() {
      this.destoryTencentMap()
      this.scriptDom = document.createElement("script");
      this.scriptDom.type = "text/javascript"
      this.scriptDom.src = "https://map.qq.com/api/gljs?v=2.exp&key=" + this.mapKey
      document.body.appendChild(this.scriptDom)
    },
    /**
     * 销毁腾讯资源地图
     */
    destoryTencentMap () {
      if (this.map) {
        this.map.destroy();
        this.map = null
      }
      if (this.markerLayer) {
        this.markerLayer = null
      }
      if (this.scriptDom) {
        document.removeChild(this.scriptDom)
        this.scriptDom = null;
      }
    },
    // 打开地图选择
    handleOpenDialog () {
      this.dialogVisible = true
      setTimeout(() => {
        this.$nextTick(() => {
          this.map = new TMap.Map(document.querySelector("#map"), {
            center: new TMap.LatLng((this.value.latitude || 39.984120), (this.value.longitude || 116.307484)),//设置地图中心点坐标
            zoom: 17.2,   //设置地图缩放级别
            pitch: 43.5,  //设置俯仰角
            rotation: 45    //设置地图旋转角度
          })
          this.markerLayer = new window.TMap.MultiMarker({
            map: this.map,
            styles: {
              //创建一个styleId为"myStyle"的样式（styles的子属性名即为styleId）
              "myStyle": new window.TMap.MarkerStyle({
                "width": 25,  // 点标记样式宽度（像素）
                "height": 35, // 点标记样式高度（像素）
                'background': 'pink',
                //焦点在图片中的像素位置，一般大头针类似形式的图片以针尖位置做为焦点，圆形点以圆心位置为焦点
                "anchor": {x: 16, y: 32}
              })
            },
            geometries: [
              {
                "id": "1",
                "styleId": 'myStyle',
                "position": new window.TMap.LatLng((this.value.latitude || 39.984120), (this.value.longitude || 116.307484))
              }
            ]
          })
          this.bindEvents()
        })
      }, 0)
    },
    remoteMethod (query) {
      if (query) {
        this.loading = true
        mapPlaceSuggestionApi(query).then(e => {
          this.results = (e || []).map((item) => {
            return {
              address: item.address,
              addressName: item.title,
              id: item.id,
              latitude: item.location.lat,
              longitude: item.location.lng,
            }
          })
        }).finally(() => {
          this.loading = false
        })
      }
    },
    /**
     * 更新选择点
     * @param lat
     * @param lng
     */
    updatePoint (lat, lng) {
      this.markerLayer.updateGeometries([
        {
          "styleId":"marker",
          "id": "1",
          "position": new TMap.LatLng(lat, lng),
        }
      ])
    },
    /**
     * 更新中心区域
     * @param lat
     * @param lng
     */
    updateCenter (lat, lng) {
      this.map.setCenter(new TMap.LatLng(lat,lng));
    },
    /**
     * 绑定点击事件
     */
    bindEvents () {
      this.map.on('click', e => {
        let {lat, lng} = e.latLng;
        this.updatePoint(lat, lng)
        mapPlaceGeocoderLocationApi(`${lat},${lng}`).then(e => {
          this.$emit("input", {
            address: e.address,
            latitude: lat,
            longitude: lng,
            addressName: e.formatted_addresses.recommend
          })
        })
      })
    },
    /**
     * 下拉框选择触发
     * @param val
     */
    pointerChange (val) {
      if (!val) {
        return;
      }
      let item = this.results.find(i => i.id == val)
      if (item && item.address) {
        this.updatePoint(item.latitude, item.longitude)
        this.$emit("input", {
          address: item.address,
          latitude: item.latitude,
          longitude: item.longitude,
          addressName: item.addressName
        })
        this.updateCenter(item.latitude, item.longitude)
      }
    },
    beforeClose (done) {
      this.destoryTencentMap()
      done()
    }
  },
  created() {
    this.loadTencentMap()
  }
}
</script>

<template>
  <div class="map-container">
    <el-input :disabled="disabled" @focus="handleOpenDialog" size="small" :value="value?.addressName" suffix-icon="el-icon-location" :readonly="true"></el-input>
    <el-dialog :show-close="false" :close-on-press-escape="false" :before-close="beforeClose" :destroy-on-close="true" :close-on-click-modal="false" :append-to-body="true" title="位置选择" :visible.sync="dialogVisible" width="600px">
      <el-select @change="pointerChange" style="width: 100%" filterable remote :remote-method="remoteMethod" size="small" v-model="select">
        <el-option v-for="(item, index) in results" :key="index" :label="item.address" :value="item.id"></el-option>
      </el-select>
      <div>当前选择位置：{{value.address}}{{ value.addressName }}</div>
      <div style="width: 100%; height: 10px"></div>
      <div id="map"></div>
      <span slot="footer" class="dialog-footer">
        <el-button size="small" @click="dialogVisible = false">取 消</el-button>
        <el-button size="small" type="primary" @click="dialogVisible = false">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<style lang="scss" scoped>
.map-container {
  width: 100%;
  #map {
    width: 100%;
    height: 400px;
  }
}
</style>
