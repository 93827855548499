import Vue from 'vue'
import '@babel/polyfill'
import App from './App.vue'
import router from './router'
import store from './store'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import MUI from "m-ui-crud"
import 'm-ui-crud/lib/m-ui.css'
import '@/assets/styles/index.scss';
// 分页组件
import Pagination from "@/components/Pagination/index.vue"
// 视频上传
import VideoUpload from "@/mui/components/VideoUpload";
// 图片上传
import PictureUpload from "@/mui/components/PictureUpload";
// 富文本
import WangEditor from "@/mui/components/WangEditor";
// 附件上传
import FileUpload from "@/mui/components/FileUpload"
// 商品价格修改附件上传
import ProductPriceFile from "@/mui/components/ProductPriceFile"
// 地图组件
import Map from "@/mui/components/Map"

// @ts-ignore
import filter from "./mixins/filters"

Vue.config.productionTip = false

Vue.use(ElementUI);
// Vue.use(MUI, {
//   dicHeaders: { Authorization: `Bearer ${store.getters.token || ''}`}
// })
Vue.prototype.$MCRUD = Object.assign(MUI.config, {dicHeaders: { Authorization: `Bearer ${store.getters.token || ''}`}})
Vue.use(MUI)
Vue.component('Pagination', Pagination)
Vue.component('VideoUpload', VideoUpload)
Vue.component('PictureUpload', PictureUpload)
Vue.component('WangEditor', WangEditor)
Vue.component("FileUpload", FileUpload)
Vue.component("ProductPriceFile", ProductPriceFile)
Vue.component("Map", Map)
Vue.mixin(filter)
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
